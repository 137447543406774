import cx from 'classnames';
import { ROUTES } from '../../constants/routes';
import { LinkButton } from '../Button/LinkButton';
import { FireIcon } from '../FireIcon/FireIcon';
import { Text } from '../Text/Text';
import { View } from '../View/View';
import styles from './Error.module.scss';

interface ErrorProps {
  statusCode: number;
  title: string;
}

export const Error = ({ statusCode, title }: ErrorProps) => {
  return (
    <View
      as="main"
      sideVariant="lg"
      text="headline-xl-bold"
      className={cx('page', styles.error)}
    >
      <FireIcon alt="" className={styles.fireIcon} animation />
      <View className={styles.container}>
        <View as="h1" className={styles.h1}>
          <Text className={styles.statusCode}>{statusCode}</Text>

          <Text className={styles.title}>{title}</Text>
        </View>

        <Text variant="body-r" className={styles.desc} as="p">
          {`Sorry, that page doesn’t exist or has been removed. Try going back home to return to the map.`}
        </Text>
        <LinkButton
          variant="primary"
          href={ROUTES.MAP}
          className={styles.button}
          tag="click_error404_backtohomepage"
        >
          Back to homepage
        </LinkButton>
      </View>
    </View>
  );
};
